<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "ThankYou",

  setup() {
    const store = useStore()
    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })
  }
}
</script>

<template>
  <div class="row">
    <div class="box">
      <h2 class="main-heading">Thank You! A representative will contact you shortly!</h2>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>